/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp		:768px;
$breakpoint_tb		:1200px;
$breakpoint_pc		:$breakpoint_sp + 1;
$sp_1200		:1200px;
$pc_1200		:$sp_1200 + 1;
$breakpoint_pc1700		:1700px;


/* Color */
$c_base : #17184b; /*サイトカラー*/
$c_purple: #17184b;/*パープル*/
$c_orange: #f07800;/*オレンジ*/
$c_border: #c1c1d0;/*オレンジ*/


@mixin maxPC {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
}

@mixin fontJa {
 font-family: 'Noto Sans JP', sans-serif;
}

@mixin fontMi {
 font-family: 'Noto Serif JP', serif;
}

@mixin fontEn {
 font-family: 'Jost', sans-serif;
}

/* other */
$ttf: cubic-bezier(0.25, 0.1, 0, 0.72);/*transition-timing-function*/