body {
  @if $type=="" {
    font-weight: 400;
    color: $c_purple;
    @include fontJa;
  }

  @if $type==sp {
    font-size: 14px;
  }

  @if $type==pc {
    font-size: 16px;
  }
}

i {
  @if $type=="" {
    font-style: normal;
  }
}

a {
  @if $type=="" {
    color: #000000;
    text-decoration: none;
  }
}

a[href^="tel:"] {
  @if $type==pc {
    pointer-events: none;
  }
}

ul,
ol {
  @if $type=="" {
    list-style-type: none;
  }
}

.ib {
  display: inline-block !important;
}

img {
  @if $type=="" {
    max-width: 100%;
    height: auto;
    //  image-rendering: -webkit-optimize-contrast;
  }
}

* {
  @if $type=="" {
    box-sizing: border-box;
  }
}

sup,
sub {
  @if $type=="" {
    font-size: .6em;
  }
}

//================================================
// ヘッダー関連
//================================================
// ヘッダー関連は_common_mq_1200.scssで管理

//================================================
// フッター関連
//================================================
.btmContents {
  .btmContentsLead {
    @if $type=="" {
      background-color: $c_purple;
      color: #fff;
      font-weight: bold;
      text-align: center;
    }

    @if $type==sp {
      font-size: 19px;
      padding: 35px 20px;
      line-height: 1.57;
    }

    @if $type==pc {
      font-size: 26px;
      padding: 35px 60px;
      line-height: 1.69;
    }
  }

  .btmContentsList {
    @if $type=="" {
      border-bottom: 1px solid #b8b8b8;
    }

    @if $type==sp {}

    @if $type==pc {
      display: flex;
    }

    li {
      @if $type=="" {
        text-align: center;
      }

      @if $type==sp {
        &+li {
          border-top: 1px solid #b8b8b8;
        }

        &.listItemContact.noLink {
          padding: 35px 20px;
        }
      }

      @if $type==pc {
        width: calc(100% * 1/3);

        &:not(:last-child) {
          border-right: 1px solid #b8b8b8;
        }
      }

      @if $type==pc {
        &.listItemDownload {
          &>a {
            &::after {
              background-image: url(../images/common/listitem_img01.jpg);
            }

            &:hover {
              .icon.iconDocument {
                background-image: url(../images/common/icon_document_white.svg);
              }
            }
          }
        }

        &.listItemContact {
          &>a {
            &::after {
              background-image: url(../images/common/listitem_img02.jpg);
            }

            &:hover {
              .icon.iconMail {
                background-image: url(../images/common/icon_mail_white.svg);
              }
            }
          }

          &.noLink {
            padding: 45px 3.1% 35px;
            display: flex;
            flex-direction: column;
          }
        }
      }

      &>a {
        @if $type=="" {
          width: 100%;
          height: 100%;
          position: relative;
          text-decoration: none;
          overflow: hidden;
          transition: .3s all $ttf;
        }

        @if $type==sp {
          display: block;
          padding: 35px 20px;
        }

        @if $type==pc {
          display: flex;
          flex-direction: column;
          min-height: 305px;
          padding: 45px 9.3% 35px;

          &:hover {
            color: #fff;

            .title {
              color: #fff;
            }

            .commonBtn {
              background-color: $c_purple;
              color: #fff;

              &::before {
                background-image: url(../images/common/icon_arrow_white.svg);
              }
            }

            &::after {
              opacity: 1;
            }
          }
        }

        &::after {
          @if $type=="" {
            content: "";
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 100%;
            height: 100%;
            transition: .3s all $ttf;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
          }

          @if $type==pc {
            opacity: 0;
          }
        }
      }

      .title {
        @if $type=="" {
          color: $c_purple;
          font-weight: 500;
          transition: .3s color $ttf;
        }

        @if $type==sp {
          font-size: 19px;
        }

        @if $type==pc {
          font-size: 24px;
        }

        .icon {
          &.iconDocument {
            @if $type=="" {
              background: url(../images/common/icon_document.svg) no-repeat center left;
            }

            @if $type==sp {
              padding-left: 30px;
            }

            @if $type==pc {
              padding-left: 35px;
            }
          }

          &.iconMail {
            @if $type=="" {
              background: url(../images/common/icon_mail.svg) no-repeat center left;
            }

            @if $type==sp {
              padding-left: 35px;
            }

            @if $type==pc {
              padding-left: 40px;
            }
          }

          &.iconMailMagazine {
            @if $type=="" {
              position: relative;
            }

            @if $type==sp {
              padding-left: 35px;
            }

            @if $type==pc {
              padding-left: 40px;
            }

            &::before {
              @if $type=="" {
                content: "";
                background: url(../images/common/icon_mailmagazine.svg) no-repeat center / 100%;
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
              }

              @if $type==sp {
                width: 27px;
                height: 29px;
              }

              @if $type==pc {
                width: 27px;
                height: 29px;
              }
            }
          }
        }
      }

      .magagineTxt {
        @if $type=="" {
          margin-bottom: 10px;
        }
      }

      .magaginePolicy {
        @if $type=="" {
          margin-top: 0;
        }

        @if $type==sp {
          font-size: 11px;
        }

        @if $type==pc {
          font-size: 12px;
        }
      }

      p {
        @if $type==sp {
          font-size: 14px;
          margin-top: 20px;
          line-height: 1.86;
        }

        @if $type==pc {
          margin: 30px 0 25px;
          line-height: 1.75;
        }
      }

      .btnWrap {
        @if $type=="" {
          .commonBtn {
            margin: 0 auto;
          }
        }

        @if $type==sp {
          margin-top: 25px;
        }

        @if $type==pc {
          margin-top: auto;

          .commonBtn {
            max-width: 288px;
            width: inherit;
          }
        }
      }

      .mailMagazine {
        @if $type==sp {
          margin-top: 25px;
        }

        @if $type==pc {
          margin-top: auto;
        }

        .magazineInput {
          @if $type=="" {
            max-width: 100%;
            border: 1px solid #d1d1d1;
            border-radius: 3px;
          }

          @if $type==sp {
            width: 100%;
            max-width: 515px;
            padding: 5px;
          }

          @if $type==tb {
            width: 100%;
            max-width: 100%;
          }

          @if $type==pc {

            width: calc(100% - 105px);
            padding: 5px;
          }

          &:focus {
            @if $type=="" {
              outline: none;
            }
          }

          &::placeholder {
            @if $type=="" {
              color: #d1d1d1;
            }
          }
        }

        .magazineSubmit {
          @if $type=="" {
            font-weight: bold;
            background-color: #fff;
            border: 1px solid $c_base;
            color: $c_base;
            border-radius: 3px;
          }

          @if $type==sp {
            box-shadow: 1px 1px 0 0 $c_purple;
            width: 94px;
            padding: 5px;
            margin-top: 20px;
          }

          @if $type==tb {
            margin-top: 20px;
          }

          @if $type==pc {
            box-shadow: 2px 2px 0 0 $c_purple;
            cursor: pointer;
            width: 94px;
            padding: 5px;
            margin-left: 7px;
            transition: .3s $ttf;

            &:hover {
              background-color: $c_base;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.btmContentsTel {
  @if $type==sp {
    padding: 30px 10px;
    text-align: center;
  }

  @if $type==pc {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
  }

  .telText {
    @if $type=="" {
      color: $c_purple;
      font-weight: 500;
    }

    @if $type==sp {
      font-size: 19px;
    }

    @if $type==pc {
      font-size: 24px;
      margin-right: 70px;
    }
  }

  .telNum {
    @if $type==sp {
      margin-top: 20px;
    }

    @if $type==pc {
      margin: 0;
    }

    a {
      @if $type=="" {
        background: url(../images/common/icon_smartphone.svg) no-repeat center left;
        color: $c_purple;
        font-weight: 500;
        @include fontEn;
      }

      @if $type==sp {
        font-size: 29px;
        padding-left: 35px;
      }

      @if $type==pc {
        font-size: 40px;
        padding-left: 45px;
      }
    }
  }
}

#pageTop {
  @if $type=="" {
    cursor: pointer;
    position: absolute;
    z-index: 2;
  }

  @if $type==sp {
    top: -10px;
    right: 10px;

    span {
      width: 40px;
      height: 40px;
    }
  }

  @if $type==pc {
    top: -20px;
    right: 25px;

    &:hover {
      span {
        background-image: url(../images/common/pagetop_hov.svg);
      }
    }
  }

  span {
    @if $type=="" {
      background: url(../images/common/pagetop.svg) no-repeat;
      background-size: contain;
      width: 52px;
      height: 53px;
      display: block;
      transition: .3s background $ttf;
    }
  }
}

#globalFooter {
  @if $type=="" {
    background-color: $c_purple;
    color: #fff;
    position: relative;
  }

  @if $type==sp {
    padding: 40px 0 0;
  }

  @if $type==pc {
    padding: 60px 0 0;
  }

  .footInfoBlock {
    .inner {
      @if $type==pc {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .logoArea {
    @if $type=="" {
      text-align: center;
    }

    @if $type==sp {}

    @if $type==pc {
      align-self: center;
      margin-right: 10px;
    }

    img {
      @if $type==sp {
        width: 92px;
      }

      @if $type==pc {
        width: 122px;
      }
    }
  }

  .textArea {
    @if $type==sp {
      font-size: 12px;
      margin-top: 25px;
      line-height: 1.75;
      text-align: center;
    }

    @if $type==pc {
      font-size: 13px;
      line-height: 2.23;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .navList {
    li {
      a {
        @if $type=="" {
          color: #fff;
        }

        @if $type==sp {
          font-size: 13px;
        }

        @if $type==pc {
          font-size: 15px;
        }

        // &[target="_blank"]{
        //   padding-right: 25px;
        //   background: url(../images/common/icon_blank.svg) no-repeat center right;
        // }


      }
    }
  }

  .footNavBlock {
    @if $type==sp {
      margin-top: 30px;
    }

    @if $type==pc {
      margin-top: 60px;
    }

    .inner {
      @if $type==pc {
        display: flex;
        justify-content: space-between;
      }
    }

    .navArea {
      @if $type==pc {
        width: 75%;
      }
    }

    .navList {
      @if $type=="" {
        display: flex;
        flex-flow: wrap;
      }

      @if $type==sp {
        margin-top: 40px;
      }

      @if $type==pc {}

      &>li {
        @if $type==sp {
          width: calc(50% - 5px);

          &:nth-child(n + 3) {
            margin-top: 20px;
          }

          &:nth-child(odd) {
            margin-right: 10px;
          }
        }

        @if $type==pc {
          width: calc(25% - 10px);

          &:nth-child(n + 5) {
            margin-top: 40px;
          }

          &:not(:nth-child(4n)) {
            margin-left: 10px;
          }
        }

        &>a {
          @if $type=="" {
            position: relative;
          }

          @if $type==sp {
            font-size: 12px;
            padding-left: 25px;
          }

          @if $type==pc {
            font-size: 15px;
            padding-left: 30px;

            &:hover {
              span::before {
                transform: scale(1, 1);
              }
            }
          }

          &::before {
            content: "";
            background-color: #fff;
            display: block;
            position: absolute;
            width: 15px;
            height: 1px;
            top: 10px;
            left: 0;
          }

          span {
            position: relative;

            &::before {
              content: "";
              background-color: #fff;
              display: inline-block;
              position: absolute;
              width: 100%;
              height: 1px;
              left: 0;
              bottom: -8px;
              transform-origin: left;
              transform: scale(0, 1);
              transition: .3s transform $ttf;
            }
          }

          &[target="_blank"] {
            @if $type=="" {
              margin-right: 5px;

              &::after {
                content: "";
                display: inline-block;
                width: 17px;
                height: 13px;
                background: url(../images/common/icon_blank.svg) no-repeat 0 0;
                background-size: cover;
                vertical-align: middle;
                margin-left: 8px;
                position: relative;
                top: 0;
              }
            }
          }
        }

        &.snsItem {
          @if $type=="" {
            width: 100%;
          }

          @if $type==sp {
            margin-top: 24px;
          }

          ul {
            @if $type=="" {
              display: flex;
              align-items: center;
            }

            li {
              &:not(:last-child) {
                margin-right: 20px;
              }

              a {
                @if $type==pc {
                  transition: .3s opacity $ttf;

                  &:hover {
                    opacity: 0.7;
                  }
                }
              }

              .xIcon {
                img {
                  @if $type=="" {
                    width: 21px;
                    height: 28px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .bnrArea {
      @if $type==sp {
        width: 75%;
        margin: 35px auto 0;
      }

      @if $type==pc {
        width: 25%;
      }
    }

    .bnrList {
      li {
        &+li {
          @if $type==sp {
            margin-top: 25px;
          }

          @if $type==pc {
            margin-top: 20px;
          }
        }

        a {
          @if $type==pc {
            transition: .3s opacity $ttf;

            &:hover {
              opacity: 0.7;
            }
          }
        }

        img {
          @if $type==sp {}

          @if $type==pc {
            width: 310px;
          }
        }
      }
    }
  }

  .footSubNavBlock {
    @if $type=="" {
      border-top: 1px solid #32336b;
    }

    @if $type==sp {
      margin-top: 40px;
      padding: 20px 0;
    }

    @if $type==pc {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      padding: 15px 30px;
    }
  }

  .subNavList {
    @if $type=="" {
      display: flex;
      flex-flow: wrap;
    }

    @if $type==sp {
      justify-content: center;
      padding: 0 10px;
    }

    @if $type==pc {}

    li {
      &:not(:last-child) {
        @if $type==sp {
          margin-right: 30px;
        }

        @if $type==pc {
          margin-right: 30px;
        }
      }

      a {
        @if $type=="" {
          color: #fff;
        }

        @if $type==sp {
          font-size: 10px;
        }

        @if $type==pc {
          font-size: 13px;
          position: relative;

          &:hover {
            span::before {
              transform: scale(1, 1);
            }
          }
        }

        span {
          position: relative;

          &::before {
            content: "";
            background-color: #fff;
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            bottom: -8px;
            transform-origin: left;
            transform: scale(0, 1);
            transition: .3s transform $ttf;
          }
        }
      }
    }
  }

  .copyright {
    @if $type==sp {
      margin-top: 10px;
      text-align: center;
    }

    @if $type==pc {
      margin-top: 0;
    }

    small {
      @if $type=="" {
        @include fontEn;
      }

      @if $type==sp {
        font-size: 10px;
      }

      @if $type==pc {}
    }
  }
}

//================================================
// TOPと下層共通パーツ
//================================================
.btnWrap {
  @if $type==sp {
    margin-top: 20px;
  }

  @if $type==pc {
    margin-top: 40px;
  }
}

.commonBtn {
  @if $type=="" {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $c_purple;
    border-radius: 3px;
    background-color: #fff;
    color: $c_purple;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    position: relative;
    text-decoration: none;
    text-align: center;
    transition: .3s background, .3s color $ttf;
  }

  @if $type==sp {
    box-shadow: 1px 1px 0 0 $c_purple;
    margin: auto;
    padding: 12px 40px;
    width: 72%;
  }

  @if $type==pc {
    box-shadow: 2px 2px 0 0 $c_purple;
    width: 288px;
    padding: 14px 40px;
    line-height: 1.2;
    transition: all .3s;

    &:hover {
      background-color: $c_purple;
      color: #fff;

      &::before {
        background-image: url(../images/common/icon_arrow_white.svg);
      }
    }
  }

  &::before {
    @if $type=="" {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 15px;
      height: 13px;
      right: 16px;
      margin: auto;
      background: url(../images/common/icon_arrow.svg) no-repeat;
      background-size: contain;
    }
  }

  &.btnCenter {
    @if $type=="" {
      margin: 0 auto;
    }
  }

  &.btnRight {
    @if $type=="" {
      margin: 0 0 0 auto;
    }
  }

  &.btnBack {
    @if $type==sp {
      padding: 12px 20px 12px 30px;
    }

    @if $type==pc {
      font-size: 15px;
      padding: 14px 45px 14px 45px;
      width: 346px;

      &:hover {

        &::before,
        &::after {
          background-image: url(../images/common/icon_arrow_white.svg);
        }
      }
    }

    &::before {
      @if $type=="" {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 23px;
        height: 23px;
        left: 24px;
        margin: auto;
        // background: url(../images/common/icon_arrow_black.svg) no-repeat;
        background-size: contain;
        transform: rotate(180deg);
      }
    }
  }

  &.btnOrange {
    @if $type=="" {
      background-color: #f07800;
      color: #fff;
      border: 1px solid #e8651a;

      &:before {
        background-image: url(../images/common/icon_arrow_white.svg);
      }
    }

    @if $type==sp {
      box-shadow: 1px 1px 0 0 #e8651a;
    }

    @if $type==pc {
      box-shadow: 2px 2px 0 0 #e8651a;

      &:hover {
        background-color: #e8651a;
      }
    }
  }
}

.btnS {
  @if $type==pc {
    width: 25%;
    min-width: 346px;
  }
}

.btnM {
  @if $type==pc {
    width: 40%;
  }
}

.btnL {
  @if $type==pc {
    width: 60%;
  }
}

.btnCenter {
  @if $type=="" {
    display: flex;
    justify-content: center;
  }
}

[class*="btnLine"] {
  .commonBtn+.commonBtn {
    @if $type==sp {
      margin-top: 20px;
    }
  }
}

.btnLine2 {
  @if $type==sp {
    a {
      margin-bottom: 25px;
    }
  }

  @if $type==pc {
    display: flex;
    overflow: hidden;
    margin-bottom: 50px;

    a {
      float: left;
      width: 49%;
      margin-right: 1%;
      margin-bottom: 10px;

      &:nth-child(2n) {
        width: 50%;
        margin-right: 0;
      }
    }
  }
}

//.btnLine2

.btnLine3 {
  @if $type==sp {
    a {
      margin-bottom: 25px;
    }
  }

  @if $type==pc {
    display: flex;
    overflow: hidden;
    margin-bottom: 50px;
  }

  a {
    @if $type==pc {
      width: 32%;
      float: left;
      margin-right: 1%;
      margin-bottom: 10px;
    }

    &.commonWhBtn {
      margin-bottom: 10px;
    }

    &:nth-child(3n) {
      @if $type==pc {
        width: 34%;
        margin-right: 0;
      }
    }
  }
}

//.btnLine3

.btnLine4 {
  @if $type==sp {
    a {
      margin-bottom: 25px;
    }
  }

  @if $type==pc {
    overflow: hidden;
    margin-bottom: 50px;
  }

  a {
    @if $type==pc {
      float: left;
      width: 24%;
      margin-right: 1%;
      margin-bottom: 10px;
    }

    &.commonWhBtn {
      margin-bottom: 10px;
    }

    &:nth-child(4n) {
      @if $type==pc {
        width: 25%;
        margin-right: 0;
      }
    }
  }
}

//.btnLine4


.moreBtn {
  @if $type=="" {
    border-top: 2px solid #e0e0e0;
    margin-top: 20px;
    padding-top: 10px;
    position: relative;
    text-align: right;
  }

  &::before {
    @if $type=="" {
      content: "";
      background-color: $c_purple;
      display: block;
      position: absolute;
      width: 136px;
      height: 2px;
      top: -2px;
      right: 0;
    }
  }

  &>span,
  &>a {
    @if $type=="" {
      background: url(../images/common/icon_arrow_more.svg) no-repeat center right;
      background-size: contain;
      color: $c_purple;
      display: inline-block;
      @include fontEn;
      font-weight: 500;
      position: relative;
      transition: .3s background $ttf;
    }

    @if $type==sp {
      padding-right: 35px;
    }

    @if $type==pc {
      font-size: 17px;
      padding-right: 40px;
    }
  }
}

//================================================
// TOPと下層共通レイアウト
//================================================
//お知らせリスト
#news {
  @if $type=="" {
    background-color: #f9f9f9;
  }

  h2 {
    @if $type==sp {
      margin-bottom: 20px;
    }

    @if $type==pc {
      margin-bottom: 40px;
    }
  }

  .inner {
    @if $type==pc {
      width: 910px;
    }
  }
}

.inner.archive {
  @if $type==pc {
    width: 910px;
  }
}

.newsList {
  @if $type==sp {}

  @if $type==pc {}

  li {
    @if $type=="" {
      position: relative;
    }

    @if $type==sp {
      margin-bottom: 20px;
    }

    @if $type==pc {
      margin-bottom: 30px;

      &:hover {
        &::after {
          transform: scale(1, 1);
        }
      }

    }

    &::before {
      @if $type=="" {
        content: "";
        display: block;
        background-color: #b8b8b8;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    &::after {
      @if $type=="" {
        content: "";
        display: block;
        background-color: $c_purple;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: scale(0, 1);
        transform-origin: left top;
        transition: .4s transform $ttf;
      }
    }

    a {
      @if $type=="" {
        color: $c_base;
        text-decoration: none;
        position: relative;
        display: flex;
      }

      @if $type==sp {
        padding-bottom: 20px;
      }

      @if $type==pc {
        padding-bottom: 30px;

        &:hover {
          &::after {
            right: 15px;
          }
        }

      }

      &::after {
        @if $type=="" {
          content: "";
          display: block;
          background: url(../images/common/icon_arrow.svg) no-repeat center / 100%;
          position: absolute;
          width: 15px;
          height: 13px;
          top: 12px;
          transform: translateY(-50%);
        }

        @if $type==sp {
          right: 0;
        }

        @if $type==pc {
          right: 20px;
          transition: .3s;
        }
      }

    }
  }

  .date {
    @if $type=="" {
      @include fontEn;
      font-weight: 600;
      margin-top: 2px;
    }

    @if $type==sp {
      width: 27.6%;
    }

    @if $type==pc {
      width: 12.8%;
      padding: 0 1.7%;
      font-size: 14px;
    }
  }

  .newsTitle {
    @if $type=="" {
      margin-top: 0;
    }

    @if $type==sp {
      width: 72.4%;
      padding-right: 12%;
    }

    @if $type==pc {
      width: 87.2%;
      padding-right: 7.6%;
    }
  }
}

//================================================
// スライダー
//================================================
// 「恒電社の実績」と「自家消費型太陽光発電の導入は～」の共通クラス
.slideList {
  @if $type==pc {
    display: flex;
    margin-top: 40px;
  }

  .slideListItem {

    //PCはスライダー使わなくなったが一応記述残してる
    // クラス「noSlideListPC」の記述確認
    @if $type==pc {
      width: calc(33.3% - 1.0%);

      &:not(:last-child) {
        margin-right: 1.0%;
      }
    }

    .img {
      @if $type=="" {
        overflow: hidden;

        img {
          width: 100%;
          transition: .3s transform $ttf;
        }
      }
    }

    &>a {
      @if $type=="" {
        display: block;
        transition: .3s transform $ttf;
        text-decoration: none;
      }

      @if $type==pc {
        &:hover {
          img {
            transform: scale(1.1);
          }

          .moreBtn>span {
            background-image: url(../images/common/icon_arrow_more_hov.svg);
          }
        }
      }
    }

    .slideListTitle {
      @if $type=="" {
        color: $c_purple;
        font-weight: bold;
        margin-top: 16px;
      }
    }

    .slideListSub {
      @if $type=="" {
        color: $c_base;
      }

      @if $type==sp {
        font-size: 12px;
        margin-top: 15px;
      }

      @if $type==pc {
        font-size: 13px;
        margin-top: 15px;
      }
    }
  }

  &.noSlideListPC {
    @if $type==pc {
      flex-flow: wrap;
    }

    .slideListItem {
      @if $type==pc {
        width: calc((100% / 3) - 27px);

        &:not(:last-child) {
          margin-right: 0;
        }

        &:not(:nth-of-type(3n + 1)) {
          margin-left: 40px;
        }

        &:nth-child(n + 4) {
          margin-top: 40px;
        }
      }
    }
  }
}

//slick 調整
.slick-list {
  @if $type=="" {}

  @if $type==sp {
    padding: 30px 8% !important;
  }

  @if $type==pc {
    padding: 30px 0 !important;
  }

  .img {
    @if $type=="" {
      overflow: hidden;

      img {
        width: 100%;
        transition: .3s transform $ttf;
      }
    }
  }

  .slick-slide {
    @if $type==sp {
      margin: 0 10px;
    }

    @if $type==pc {
      @if $type==pc {
        transform: scale(0.95);
        transition: .3s transform $ttf;
        transform-origin: bottom;

        &:not(:last-child) {
          margin-right: 0;
        }
      }

      &:not(.slick-center) {
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

.slick-dots {
  @if $type=="" {
    display: block;
    text-align: center;
    padding: 0;
    margin: -2px 0 0;
    width: 100%;
  }

  @if $type==sp {
    padding: 0 100px;
  }

  @if $type==pc {
    padding-right: 120px;
    text-align: right;
  }

  li {
    @if $type=="" {
      position: relative;
      display: inline-block;
      margin: 0 8px;
      cursor: pointer;
    }

    @if $type==sp {
      height: 10px;
      width: 10px;
    }

    @if $type==pc {
      height: 10px;
      width: 10px;
    }

    button {
      @if $type=="" {
        background: transparent;
        border-radius: 50%;
        border: 1px solid $c_purple;
        display: block;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        padding: 4px;
        cursor: pointer;
      }

      @if $type==sp {
        height: 7px;
        width: 7px;
      }

      @if $type==pc {
        height: 7px;
        width: 7px;
      }


      &:hover,
      &:focus {
        outline: none;
      }

      &:before {
        @if $type=="" {
          position: absolute;
          top: 0;
          left: 0;
          line-height: 20px;
          text-align: center;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        @if $type==sp {
          width: 20px;
          height: 20px;
        }

        @if $type==pc {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.slick-active {
      button {
        background: $c_purple;
      }

    }
  }
}

.slick-arrow {
  @if $type=="" {
    display: block;
    background-color: #fff;
    border: none;
    font-size: 0;
    border: 1px solid $c_purple;
    z-index: 3;
    cursor: pointer;
    transition: .3s all $ttf;

    &::before {
      transition: .3s border $ttf;
    }
  }

  @if $type==sp {
    box-shadow: 1px 1px 0 0 $c_purple;
    width: 40px;
    height: 38px;
  }

  @if $type==pc {
    box-shadow: 2px 2px 0 0 $c_purple;
    width: 40px;
    height: 30px;

    &:hover {
      background-color: $c_purple;

      &::before {
        border-color: #fff;
      }
    }
  }

  &.prev {
    @if $type=="" {
      border-right: none;
      border-radius: 3px 0 0 3px;
      cursor: pointer;
      position: absolute;
      bottom: -10px;
      right: 60px;
    }

    &::before {
      @if $type=="" {
        content: "";
        display: block;
        border-right: 1px solid $c_purple;
        border-bottom: 1px solid $c_purple;
        position: absolute;
        transform: translate(-50%, -50%)rotate(-225deg);
      }

      @if $type==sp {
        top: calc(50% + 1px);
        left: calc(50% + 2px);
        width: 8px;
        height: 8px;
      }

      @if $type==pc {
        top: calc(50% + 1px);
        left: calc(50% + 2px);
        width: 8px;
        height: 8px;
      }
    }
  }

  &.next {
    @if $type=="" {
      border-radius: 0 3px 3px 0;
      cursor: pointer;
      position: absolute;
      bottom: -10px;
      right: 20px;
    }

    &::before {
      @if $type=="" {
        content: "";
        display: block;
        border-right: 1px solid $c_purple;
        border-bottom: 1px solid $c_purple;
        position: absolute;
        transform: translate(-50%, -50%)rotate(-45deg);
      }

      @if $type==sp {
        top: calc(50% - -1px);
        left: calc(50% - 2px);
        width: 8px;
        height: 8px;
      }

      @if $type==pc {
        top: calc(50% - -1px);
        left: calc(50% - 2px);
        width: 8px;
        height: 8px;
      }

    }
  }
}

#notFound {

  // margin-top: 100px;
  @if $type==sp {}

  @if $type==pc {}

  .ttlArea {
    @if $type=="" {
      text-align: center;
    }

    h1 {
      @if $type==sp {
        font-size: 30px;
      }

      @if $type==pc {
        font-size: 40px;
      }
    }

    p {
      @if $type=="" {
        font-weight: bold;
        margin-top: 10px;
      }

      @if $type==sp {
        font-size: 16px;
      }

      @if $type==pc {
        font-size: 18px;
      }
    }
  }

  .txt {
    @if $type=="" {
      text-align: center;
    }

    @if $type==sp {
      margin-top: 30px;
    }

    @if $type==pc {
      margin-top: 40px;
    }
  }

  .btnLine2 {
    @if $type==sp {
      margin-top: 30px;
    }

    @if $type==pc {
      margin-top: 40px;
    }
  }
}

//================================================
// FAQ
//================================================
#faq {
  @if $type==sp {
    padding: 35px 0 40px;
  }

  @if $type==pc {
    padding: 70px 0 80px;
  }

  h2 {
    @if $type=="" {
      text-align: center;
    }

    @if $type==sp {
      font-size: 23px;
    }

    @if $type==pc {
      font-size: 34px;
    }
  }

  h3.title {
    @if $type=="" {
      font-weight: bold;
      border-left: 3px solid $c_base;
      position: relative;

      &:before {
        content: "";
        display: inline-block;
        width: 3px;
        height: 50%;
        background-color: #dddddd;
        position: absolute;
        top: 0;
        left: -3px;
      }
    }

    @if $type==sp {
      font-size: 18px;
      padding-left: 20px;
    }

    @if $type==pc {
      font-size: 20px;
      padding-left: 25px;
    }
  }


  .faqWrapper {
    @if $type=="" {
      display: flex;
      flex-wrap: wrap;
    }

    @if $type==sp {
      margin-top: 25px;
      gap: 15px;
    }

    @if $type==pc {
      margin-top: 40px;
      gap: 25px 1.65%;
    }
  }

  .faqBox {
    @if $type=="" {
      border: 1px solid #e0e0e0;
    }

    @if $type==sp {
      width: 100%;
      padding: 15px;
    }

    @if $type==pc {
      width: calc((100% * 1/3) - 1.1%);
      padding: 30px 2.1%;
    }

  }

  .faqList {
    @if $type==sp {
      margin-top: 10px;
    }

    @if $type==pc {
      margin-top: 20px;
    }

    >li {
      @if $type=="" {
        position: relative;
      }

      @if $type==sp {
        padding-left: 18px;
      }

      @if $type==pc {
        padding-left: 26px;

        &:hover {
          &:before {
            left: 5px;
          }
        }
      }

      &:not(:first-child) {
        @if $type==sp {
          margin-top: 3px;
        }

        @if $type==pc {
          margin-top: 5px;
        }
      }

      &:before {
        @if $type=="" {
          content: "";
          display: block;
          background: url(../images/common/icon_arrow_purple.svg)no-repeat center / 100%;
          position: absolute;
          left: 0;
        }

        @if $type==sp {
          top: 12px;
          width: 11px;
          height: 9px;
        }

        @if $type==pc {
          top: 10px;
          width: 15px;
          height: 13px;
          transition: .3s;
        }
      }

      >a {
        @if $type=="" {
          // position: relative;
          // text-decoration: none;
          text-decoration: underline;
          display: inline-block;
          color: $c_base;
          text-decoration-color: #b8b8b8;
        }

        @if $type==sp {
          font-size: 14px;
          line-height: 2;
          text-underline-offset: 3px;
        }

        @if $type==pc {
          font-size: 16px;
          line-height: 2;
          text-underline-offset: 5px;

          &:hover {
            text-decoration: underline;
            text-decoration-color: $c_base;

            // &::after {
            //   transform: scale(1, 1);
            // }
          }
        }

        // &::before {
        //   @if $type=="" {
        //     content: "";
        //     display: block;
        //     background-color: #b8b8b8;
        //     width: 100%;
        //     height: 1px;
        //     position: absolute;
        //     left: 0;
        //     bottom: 0;
        //   }
        // }

        // &::after {
        //   @if $type=="" {
        //     content: "";
        //     display: block;
        //     background-color: $c_purple;
        //     width: 100%;
        //     height: 1px;
        //     position: absolute;
        //     left: 0;
        //     bottom: 0;
        //     transform: scale(0, 1);
        //     transform-origin: left top;
        //     transition: .4s transform $ttf;
        //   }
        // }

      }
    }
  }

}



@import "_slick";

/* ココから下には記入禁止 */

.forPC {
  @if $type==sp {
    display: none !important;
  }
}

.forSP {
  @if $type==pc {
    display: none !important;
  }
}