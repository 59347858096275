@charset "UTF-8";

// IMPPRT INIT

@import "lib/_sanitize";    // Reset CSS
@import "_setting";
$type: "";
@import "_common";
@import "_common_mq_1200";

// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_sp) {
	$type: sp;
	@import "_common";
}

// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
	$type: pc;
	@import "_common";
}

// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:$breakpoint_tb) {
  $type: tb;
  @import "_common";
}

// FOR CHANGE DISPLAY SIZE 1200
//-----------------------------------------------------------------
@media screen and (max-width: 1200px) {
	$type: sp_1200;
	@import "_common_mq_1200";
}

@media print, screen and (min-width: 1201px) {
	$type: pc_1200;
	@import "_common_mq_1200";
}


// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}
