//================================================
// Break Pointが1200で切り替わる
//================================================
main {
	@if $type=="" {}

	@if $type==sp_1200 {
		margin-top: 60px;
	}

	@if $type==pc_1200 {
		margin-top: 75px;
	}
}

#globalheader {
	a {
		@if $type=="" {
			text-decoration: none;
		}
	}

	@if $type==sp_1200 {
		background-color: $c_purple;
		width: 100%;
		height: 60px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		transition: .3s $ttf all;

		.headInner {
			width: 100%;
			height: 60px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.logo {
				margin-left: 20px;
				position: relative;
				z-index: 10;

				a {
					display: flex;
					align-items: center;
					text-decoration: none;

					img {
						width: 92px;
					}
				}
			}

			#menubtn {
				width: 60px;
				height: 60px;
				z-index: 10;
				position: absolute;
				top: 0;
				right: 0;

				span {
					width: 23px;
					height: 2px;
					background-color: #fff;
					position: absolute;
					right: 18px;
					transition: all .4s $ttf;

					&:nth-of-type(1) {
						top: 19px;
					}

					&:nth-of-type(2) {
						top: 27px;
					}

					&:nth-of-type(3) {
						top: 35px;
					}
				}

				&.isOpen {

					span {
						&:nth-of-type(1) {
							top: 24px;
							transform: rotate(-45deg);
						}

						&:nth-of-type(2) {
							opacity: 0;
						}

						&:nth-of-type(3) {
							top: 24px;
							transform: rotate(45deg);
						}
					}
				}
			}

			nav {
				position: fixed;
				width: 100%;
				margin: 60px auto 0;
				background-color: $c_purple;
				top: 0;
				right: -100%;
				transform-origin: top;
				transform: scale(1, 1);
				z-index: 4;
				transition: all .3s;
				padding: 30px 0 60px;
				overflow: scroll;
				-ms-overflow-style: none;
				scrollbar-width: none;

				&::-webkit-scrollbar {
					display: none;
				}

				&.isOpen {
					right: 0;
				}

				.navInner {
					padding: 0 28px;

					.gNavList {
						&>li {
							position: relative;

							a {
								color: #fff;
								font-size: 14px;
								font-size: clamp(13px, 2.2vw, 15px);
								font-weight: bold;
								display: block;
								padding: 20px 30px;
							}

							&.menuShowy {
								display: inline-block;
								margin-top: 17px;
								// width: calc(50% - 3.3%);
								width: 100%;
								max-width: 600px;

								a {
									border-radius: 3px;
									display: block;
									font-weight: bold;
									padding: 15px 10px;
									text-align: center;

									span {
										position: relative;
									}

									.icon {
										display: inline-block;
										margin-right: 20px;

										&::before {
											content: "";
											background-repeat: no-repeat;
											background-size: contain;
											display: block;
											position: absolute;
											top: 50%;
											left: 0;
											transform: translateY(-50%);
											transition: .3s all;
										}

										&.iconMaterial {
											&::before {
												background-image: url(../images/common/icon_document_white.svg);
												width: 14px;
												height: 18px;
											}
										}

										&.iconMail {
											&::before {
												background-image: url(../images/common/icon_mail_white.svg);
												width: 18px;
												height: 12px;
											}
										}
									}
								}
							}

							&.menuMaterial {
								a {
									background-color: $c_orange;
									border: 1px solid #fff;
								}
							}

							&.menuBlank {
								span {
									position: relative;
								}

								span::after {
									content: "";
									display: block;
									background: url(../images/common/icon_window_white.svg) no-repeat center /100%;
									width: 12px;
									height: 12px;
									position: absolute;
									top: 50%;
									transform: translateY(-50%);
									right: -20px;
								}
							}

							&.menuContact {
								margin-right: 5%;

								a {
									background-color: #fff;
									color: $c_purple;
								}
							}

							&.telBtn {
								margin-top: 20px;
								max-width: 600px;
								background-color: #fb8d20;
								border: 1px solid #fff;
								border-radius: 3px;

								a {
									text-align: center;
									padding: 14px 10px;


									span {
										position: relative;
										@include fontEn;
										font-size: 16px;
										font-weight: 500;
										color: #fff;
										letter-spacing: 0.03em;
										padding-left: 20px;

										&::before {
											content: "";
											display: block;
											background: url(../images/common/icon_smartphone_wht.svg)no-repeat center / 100%;
											position: absolute;
											top: 50%;
											transform: translateY(-50%);
											left: 0;
											width: 15px;
											height: 23px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@if $type==pc_1200 {
		background-color: $c_purple;
		width: 100%;
		height: 75px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		transition: .3s all $ttf;

		&.isScroll {
			background-color: #fff;
			box-shadow: 2px 0px 4px 2px rgba(0, 0, 0, .05);
		}

		.headInner {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.logo {
				// margin-left: 35px;
				margin-left: 25px;
				transition: .3s all $ttf;

				a {
					display: flex;
					align-items: center;
					text-decoration: none;

					img {
						width: 92px;
					}
				}
			}

			#menubtn {
				display: none;
			}

			nav {
				height: 75px;

				.navInner {

					.gNavList {
						display: flex;
						align-items: center;
						height: 75px;
						padding-left: 10px;

						li {
							overflow: hidden;

							a {
								display: flex;
								align-items: center;
								font-size: 16px;
								font-size: clamp(12px, 1vw, 16px);
								padding: 32px 14px;
								color: #fff;
								position: relative;
								transition: .3s all $ttf;

								&:hover {
									font-weight: 500;

									span::before {
										transform: scale(1, 1);
									}
								}
							}

							span {
								position: relative;
								letter-spacing: 0.05rem;

								&::before {
									content: "";
									background-color: #fff;
									display: inline-block;
									position: absolute;
									width: 100%;
									height: 1px;
									left: 0;
									bottom: -8px;
									transform-origin: left;
									transform: scale(0, 1);
									transition: .3s transform $ttf;
								}
							}

							&:nth-last-child(2) {
								a {
									padding: 32px 32px 32px 16px;
								}
							}

							&.menuShowy {
								a {
									height: 75px;

									.icon {
										display: inline-block;
										margin-right: 20px;

										&::before {
											content: "";
											background-repeat: no-repeat;
											background-size: contain;
											display: block;
											position: absolute;
											top: 50%;
											left: 0;
											// transform: translateY(-50%);
											transform: translateY(-38%);
										}

										&.iconMaterial {
											&::before {
												background-image: url(../images/common/icon_document_white.svg);
												width: 14px;
												height: 18px;
											}
										}

										&.iconMail {
											margin-right: 25px;

											&::before {
												background-image: url(../images/common/icon_mail_white.svg);
												width: 19px;
												height: 15px;
											}
										}
									}
								}
							}

							&.menuMaterial {
								a {
									background-color: $c_orange;
									font-weight: bold;
									padding: 32px 24px;

									span::before {
										content: none;
									}

									&:hover {
										background-color: #a25507;
										color: #fff;

										.icon::before {
											// background-image: url(../images/common/icon_document_orange.svg);
											//  background-image: url(../images/common/icon_document_white.svg);
											background-image: url(../images/common/icon_mail_white.svg);
										}
									}
								}
							}

							&.menuBlank {
								a {
									padding: 32px 42px 32px 16px;
								}

								span {
									position: relative;
								}

								span::after {
									content: "";
									display: block;
									background: url(../images/common/icon_window_white.svg) no-repeat center /100%;
									width: 12px;
									height: 12px;
									position: absolute;
									top: 50%;
									transform: translateY(-50%);
									right: -18px;
								}
							}

							// &.menuContact {
							// 	a {
							// 		padding: 32px 32px 32px 16px;
							// 	}
							// }

							// &.menuContact{
							// 	margin-left: 1vw;
							//  a{
							// 	padding: 32px 10px;


							// 	 background-color: #fff;
							// 	 color: $c_purple;
							// 	 &:hover{
							// 		 background-color: $c_purple;
							// 		 color: #fff;
							// 		 .icon::before{
							// 			 background-image: url(../images/common/icon_mail_white.svg);
							// 		 }
							// 	 }
							//  }
							// }

							&.telBtn {
								height: 75px;
								background-color: #fb8d20;
								border-right: solid 1px $c_purple;

								a {
									pointer-events: none;
									padding: 23px 15px;


									span {
										position: relative;
										@include fontEn;
										font-size: 20px;
										font-weight: 500;
										color: #fff;
										letter-spacing: 0.06em;
										padding-left: 25px;

										&::before {
											content: "";
											display: block;
											background: url(../images/common/icon_smartphone_wht.svg)no-repeat center / 100%;
											position: absolute;
											top: 50%;
											transform: translateY(-50%);
											left: 0;
											width: 19px;
											height: 30px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.spHeadOverlay {
	@if $type=="" {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, .5);
		z-index: 1;
		display: none;
	}

	&.show {
		display: block;
	}
}

.forPC1200 {
	@if $type==sp_1200 {
		display: none !important;
	}
}

.forSP1200 {
	@if $type==pc_1200 {
		display: none !important;
	}
}