@charset "UTF-8";
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
}

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*パープル*/
/*オレンジ*/
/*オレンジ*/
/* other */
/*transition-timing-function*/
body {
  font-weight: 400;
  color: #17184b;
  font-family: "Noto Sans JP", sans-serif;
}

i {
  font-style: normal;
}

a {
  color: #000000;
  text-decoration: none;
}

ul,
ol {
  list-style-type: none;
}

.ib {
  display: inline-block !important;
}

img {
  max-width: 100%;
  height: auto;
}

* {
  box-sizing: border-box;
}

sup,
sub {
  font-size: 0.6em;
}

.btmContents .btmContentsLead {
  background-color: #17184b;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
.btmContents .btmContentsList {
  border-bottom: 1px solid #b8b8b8;
}
.btmContents .btmContentsList li {
  text-align: center;
}
.btmContents .btmContentsList li > a {
  width: 100%;
  height: 100%;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  transition: 0.3s all cubic-bezier(0.25, 0.1, 0, 0.72);
}
.btmContents .btmContentsList li > a::after {
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.3s all cubic-bezier(0.25, 0.1, 0, 0.72);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.btmContents .btmContentsList li .title {
  color: #17184b;
  font-weight: 500;
  transition: 0.3s color cubic-bezier(0.25, 0.1, 0, 0.72);
}
.btmContents .btmContentsList li .title .icon.iconDocument {
  background: url(../images/common/icon_document.svg) no-repeat center left;
}
.btmContents .btmContentsList li .title .icon.iconMail {
  background: url(../images/common/icon_mail.svg) no-repeat center left;
}
.btmContents .btmContentsList li .title .icon.iconMailMagazine {
  position: relative;
}
.btmContents .btmContentsList li .title .icon.iconMailMagazine::before {
  content: "";
  background: url(../images/common/icon_mailmagazine.svg) no-repeat center/100%;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.btmContents .btmContentsList li .magagineTxt {
  margin-bottom: 10px;
}
.btmContents .btmContentsList li .magaginePolicy {
  margin-top: 0;
}
.btmContents .btmContentsList li .btnWrap .commonBtn {
  margin: 0 auto;
}
.btmContents .btmContentsList li .mailMagazine .magazineInput {
  max-width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
}
.btmContents .btmContentsList li .mailMagazine .magazineInput:focus {
  outline: none;
}
.btmContents .btmContentsList li .mailMagazine .magazineInput::placeholder {
  color: #d1d1d1;
}
.btmContents .btmContentsList li .mailMagazine .magazineSubmit {
  font-weight: bold;
  background-color: #fff;
  border: 1px solid #17184b;
  color: #17184b;
  border-radius: 3px;
}

.btmContentsTel .telText {
  color: #17184b;
  font-weight: 500;
}
.btmContentsTel .telNum a {
  background: url(../images/common/icon_smartphone.svg) no-repeat center left;
  color: #17184b;
  font-weight: 500;
  font-family: "Jost", sans-serif;
}

#pageTop {
  cursor: pointer;
  position: absolute;
  z-index: 2;
}
#pageTop span {
  background: url(../images/common/pagetop.svg) no-repeat;
  background-size: contain;
  width: 52px;
  height: 53px;
  display: block;
  transition: 0.3s background cubic-bezier(0.25, 0.1, 0, 0.72);
}

#globalFooter {
  background-color: #17184b;
  color: #fff;
  position: relative;
}
#globalFooter .logoArea {
  text-align: center;
}
#globalFooter .navList li a {
  color: #fff;
}
#globalFooter .footNavBlock .navList {
  display: flex;
  flex-flow: wrap;
}
#globalFooter .footNavBlock .navList > li > a {
  position: relative;
}
#globalFooter .footNavBlock .navList > li > a::before {
  content: "";
  background-color: #fff;
  display: block;
  position: absolute;
  width: 15px;
  height: 1px;
  top: 10px;
  left: 0;
}
#globalFooter .footNavBlock .navList > li > a span {
  position: relative;
}
#globalFooter .footNavBlock .navList > li > a span::before {
  content: "";
  background-color: #fff;
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -8px;
  transform-origin: left;
  transform: scale(0, 1);
  transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
}
#globalFooter .footNavBlock .navList > li > a[target=_blank] {
  margin-right: 5px;
}
#globalFooter .footNavBlock .navList > li > a[target=_blank]::after {
  content: "";
  display: inline-block;
  width: 17px;
  height: 13px;
  background: url(../images/common/icon_blank.svg) no-repeat 0 0;
  background-size: cover;
  vertical-align: middle;
  margin-left: 8px;
  position: relative;
  top: 0;
}
#globalFooter .footNavBlock .navList > li.snsItem {
  width: 100%;
}
#globalFooter .footNavBlock .navList > li.snsItem ul {
  display: flex;
  align-items: center;
}
#globalFooter .footNavBlock .navList > li.snsItem ul li:not(:last-child) {
  margin-right: 20px;
}
#globalFooter .footNavBlock .navList > li.snsItem ul li .xIcon img {
  width: 21px;
  height: 28px;
}
#globalFooter .footSubNavBlock {
  border-top: 1px solid #32336b;
}
#globalFooter .subNavList {
  display: flex;
  flex-flow: wrap;
}
#globalFooter .subNavList li a {
  color: #fff;
}
#globalFooter .subNavList li a span {
  position: relative;
}
#globalFooter .subNavList li a span::before {
  content: "";
  background-color: #fff;
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -8px;
  transform-origin: left;
  transform: scale(0, 1);
  transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
}
#globalFooter .copyright small {
  font-family: "Jost", sans-serif;
}

.commonBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #17184b;
  border-radius: 3px;
  background-color: #fff;
  color: #17184b;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  text-align: center;
  transition: 0.3s background, 0.3s color cubic-bezier(0.25, 0.1, 0, 0.72);
}
.commonBtn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15px;
  height: 13px;
  right: 16px;
  margin: auto;
  background: url(../images/common/icon_arrow.svg) no-repeat;
  background-size: contain;
}
.commonBtn.btnCenter {
  margin: 0 auto;
}
.commonBtn.btnRight {
  margin: 0 0 0 auto;
}
.commonBtn.btnBack::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 23px;
  height: 23px;
  left: 24px;
  margin: auto;
  background-size: contain;
  transform: rotate(180deg);
}
.commonBtn.btnOrange {
  background-color: #f07800;
  color: #fff;
  border: 1px solid #e8651a;
}
.commonBtn.btnOrange:before {
  background-image: url(../images/common/icon_arrow_white.svg);
}

.btnCenter {
  display: flex;
  justify-content: center;
}

.btnLine3 a.commonWhBtn {
  margin-bottom: 10px;
}
.btnLine4 a.commonWhBtn {
  margin-bottom: 10px;
}
.moreBtn {
  border-top: 2px solid #e0e0e0;
  margin-top: 20px;
  padding-top: 10px;
  position: relative;
  text-align: right;
}
.moreBtn::before {
  content: "";
  background-color: #17184b;
  display: block;
  position: absolute;
  width: 136px;
  height: 2px;
  top: -2px;
  right: 0;
}
.moreBtn > span, .moreBtn > a {
  background: url(../images/common/icon_arrow_more.svg) no-repeat center right;
  background-size: contain;
  color: #17184b;
  display: inline-block;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  position: relative;
  transition: 0.3s background cubic-bezier(0.25, 0.1, 0, 0.72);
}

#news {
  background-color: #f9f9f9;
}
.newsList li {
  position: relative;
}
.newsList li::before {
  content: "";
  display: block;
  background-color: #b8b8b8;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.newsList li::after {
  content: "";
  display: block;
  background-color: #17184b;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: scale(0, 1);
  transform-origin: left top;
  transition: 0.4s transform cubic-bezier(0.25, 0.1, 0, 0.72);
}
.newsList li a {
  color: #17184b;
  text-decoration: none;
  position: relative;
  display: flex;
}
.newsList li a::after {
  content: "";
  display: block;
  background: url(../images/common/icon_arrow.svg) no-repeat center/100%;
  position: absolute;
  width: 15px;
  height: 13px;
  top: 12px;
  transform: translateY(-50%);
}
.newsList .date {
  font-family: "Jost", sans-serif;
  font-weight: 600;
  margin-top: 2px;
}
.newsList .newsTitle {
  margin-top: 0;
}

.slideList .slideListItem .img {
  overflow: hidden;
}
.slideList .slideListItem .img img {
  width: 100%;
  transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
}
.slideList .slideListItem > a {
  display: block;
  transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  text-decoration: none;
}
.slideList .slideListItem .slideListTitle {
  color: #17184b;
  font-weight: bold;
  margin-top: 16px;
}
.slideList .slideListItem .slideListSub {
  color: #17184b;
}
.slick-list .img {
  overflow: hidden;
}
.slick-list .img img {
  width: 100%;
  transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
}
.slick-dots {
  display: block;
  text-align: center;
  padding: 0;
  margin: -2px 0 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 8px;
  cursor: pointer;
}
.slick-dots li button {
  background: transparent;
  border-radius: 50%;
  border: 1px solid #17184b;
  display: block;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  padding: 4px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button {
  background: #17184b;
}

.slick-arrow {
  display: block;
  background-color: #fff;
  border: none;
  font-size: 0;
  border: 1px solid #17184b;
  z-index: 3;
  cursor: pointer;
  transition: 0.3s all cubic-bezier(0.25, 0.1, 0, 0.72);
}
.slick-arrow::before {
  transition: 0.3s border cubic-bezier(0.25, 0.1, 0, 0.72);
}
.slick-arrow.prev {
  border-right: none;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
  position: absolute;
  bottom: -10px;
  right: 60px;
}
.slick-arrow.prev::before {
  content: "";
  display: block;
  border-right: 1px solid #17184b;
  border-bottom: 1px solid #17184b;
  position: absolute;
  transform: translate(-50%, -50%) rotate(-225deg);
}
.slick-arrow.next {
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  position: absolute;
  bottom: -10px;
  right: 20px;
}
.slick-arrow.next::before {
  content: "";
  display: block;
  border-right: 1px solid #17184b;
  border-bottom: 1px solid #17184b;
  position: absolute;
  transform: translate(-50%, -50%) rotate(-45deg);
}

#notFound .ttlArea {
  text-align: center;
}
#notFound .ttlArea p {
  font-weight: bold;
  margin-top: 10px;
}
#notFound .txt {
  text-align: center;
}
#faq h2 {
  text-align: center;
}
#faq h3.title {
  font-weight: bold;
  border-left: 3px solid #17184b;
  position: relative;
}
#faq h3.title:before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 50%;
  background-color: #dddddd;
  position: absolute;
  top: 0;
  left: -3px;
}
#faq .faqWrapper {
  display: flex;
  flex-wrap: wrap;
}
#faq .faqBox {
  border: 1px solid #e0e0e0;
}
#faq .faqList > li {
  position: relative;
}
#faq .faqList > li:before {
  content: "";
  display: block;
  background: url(../images/common/icon_arrow_purple.svg) no-repeat center/100%;
  position: absolute;
  left: 0;
}
#faq .faqList > li > a {
  text-decoration: underline;
  display: inline-block;
  color: #17184b;
  text-decoration-color: #b8b8b8;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* ココから下には記入禁止 */
#globalheader a {
  text-decoration: none;
}

.spHeadOverlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: none;
}
.spHeadOverlay.show {
  display: block;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
  .ib {
    display: inline-block !important;
  }
  .btmContents .btmContentsLead {
    font-size: 19px;
    padding: 35px 20px;
    line-height: 1.57;
  }
  .btmContents .btmContentsList li + li {
    border-top: 1px solid #b8b8b8;
  }
  .btmContents .btmContentsList li.listItemContact.noLink {
    padding: 35px 20px;
  }
  .btmContents .btmContentsList li > a {
    display: block;
    padding: 35px 20px;
  }
  .btmContents .btmContentsList li .title {
    font-size: 19px;
  }
  .btmContents .btmContentsList li .title .icon.iconDocument {
    padding-left: 30px;
  }
  .btmContents .btmContentsList li .title .icon.iconMail {
    padding-left: 35px;
  }
  .btmContents .btmContentsList li .title .icon.iconMailMagazine {
    padding-left: 35px;
  }
  .btmContents .btmContentsList li .title .icon.iconMailMagazine::before {
    width: 27px;
    height: 29px;
  }
  .btmContents .btmContentsList li .magaginePolicy {
    font-size: 11px;
  }
  .btmContents .btmContentsList li p {
    font-size: 14px;
    margin-top: 20px;
    line-height: 1.86;
  }
  .btmContents .btmContentsList li .btnWrap {
    margin-top: 25px;
  }
  .btmContents .btmContentsList li .mailMagazine {
    margin-top: 25px;
  }
  .btmContents .btmContentsList li .mailMagazine .magazineInput {
    width: 100%;
    max-width: 515px;
    padding: 5px;
  }
  .btmContents .btmContentsList li .mailMagazine .magazineSubmit {
    box-shadow: 1px 1px 0 0 #17184b;
    width: 94px;
    padding: 5px;
    margin-top: 20px;
  }
  .btmContentsTel {
    padding: 30px 10px;
    text-align: center;
  }
  .btmContentsTel .telText {
    font-size: 19px;
  }
  .btmContentsTel .telNum {
    margin-top: 20px;
  }
  .btmContentsTel .telNum a {
    font-size: 29px;
    padding-left: 35px;
  }
  #pageTop {
    top: -10px;
    right: 10px;
  }
  #pageTop span {
    width: 40px;
    height: 40px;
  }
  #globalFooter {
    padding: 40px 0 0;
  }
  #globalFooter .logoArea img {
    width: 92px;
  }
  #globalFooter .textArea {
    font-size: 12px;
    margin-top: 25px;
    line-height: 1.75;
    text-align: center;
  }
  #globalFooter .navList li a {
    font-size: 13px;
  }
  #globalFooter .footNavBlock {
    margin-top: 30px;
  }
  #globalFooter .footNavBlock .navList {
    margin-top: 40px;
  }
  #globalFooter .footNavBlock .navList > li {
    width: calc(50% - 5px);
  }
  #globalFooter .footNavBlock .navList > li:nth-child(n+3) {
    margin-top: 20px;
  }
  #globalFooter .footNavBlock .navList > li:nth-child(odd) {
    margin-right: 10px;
  }
  #globalFooter .footNavBlock .navList > li > a {
    font-size: 12px;
    padding-left: 25px;
  }
  #globalFooter .footNavBlock .navList > li > a::before {
    content: "";
    background-color: #fff;
    display: block;
    position: absolute;
    width: 15px;
    height: 1px;
    top: 10px;
    left: 0;
  }
  #globalFooter .footNavBlock .navList > li > a span {
    position: relative;
  }
  #globalFooter .footNavBlock .navList > li > a span::before {
    content: "";
    background-color: #fff;
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -8px;
    transform-origin: left;
    transform: scale(0, 1);
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalFooter .footNavBlock .navList > li.snsItem {
    margin-top: 24px;
  }
  #globalFooter .footNavBlock .navList > li.snsItem ul li:not(:last-child) {
    margin-right: 20px;
  }
  #globalFooter .footNavBlock .bnrArea {
    width: 75%;
    margin: 35px auto 0;
  }
  #globalFooter .footNavBlock .bnrList li + li {
    margin-top: 25px;
  }
  #globalFooter .footSubNavBlock {
    margin-top: 40px;
    padding: 20px 0;
  }
  #globalFooter .subNavList {
    justify-content: center;
    padding: 0 10px;
  }
  #globalFooter .subNavList li:not(:last-child) {
    margin-right: 30px;
  }
  #globalFooter .subNavList li a {
    font-size: 10px;
  }
  #globalFooter .subNavList li a span {
    position: relative;
  }
  #globalFooter .subNavList li a span::before {
    content: "";
    background-color: #fff;
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -8px;
    transform-origin: left;
    transform: scale(0, 1);
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalFooter .copyright {
    margin-top: 10px;
    text-align: center;
  }
  #globalFooter .copyright small {
    font-size: 10px;
  }
  .btnWrap {
    margin-top: 20px;
  }
  .commonBtn {
    box-shadow: 1px 1px 0 0 #17184b;
    margin: auto;
    padding: 12px 40px;
    width: 72%;
  }
  .commonBtn.btnBack {
    padding: 12px 20px 12px 30px;
  }
  .commonBtn.btnOrange {
    box-shadow: 1px 1px 0 0 #e8651a;
  }
  [class*=btnLine] .commonBtn + .commonBtn {
    margin-top: 20px;
  }
  .btnLine2 a {
    margin-bottom: 25px;
  }
  .btnLine3 a {
    margin-bottom: 25px;
  }
  .btnLine3 a.commonWhBtn {
    margin-bottom: 10px;
  }
  .btnLine4 a {
    margin-bottom: 25px;
  }
  .btnLine4 a.commonWhBtn {
    margin-bottom: 10px;
  }
  .moreBtn > span, .moreBtn > a {
    padding-right: 35px;
  }
  #news h2 {
    margin-bottom: 20px;
  }
  .newsList li {
    margin-bottom: 20px;
  }
  .newsList li a {
    padding-bottom: 20px;
  }
  .newsList li a::after {
    right: 0;
  }
  .newsList .date {
    width: 27.6%;
  }
  .newsList .newsTitle {
    width: 72.4%;
    padding-right: 12%;
  }
  .slideList .slideListItem .slideListSub {
    font-size: 12px;
    margin-top: 15px;
  }
  .slick-list {
    padding: 30px 8% !important;
  }
  .slick-list .slick-slide {
    margin: 0 10px;
  }
  .slick-dots {
    padding: 0 100px;
  }
  .slick-dots li {
    height: 10px;
    width: 10px;
  }
  .slick-dots li button {
    height: 7px;
    width: 7px;
  }
  .slick-dots li button:hover, .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:before {
    width: 20px;
    height: 20px;
  }
  .slick-dots li.slick-active button {
    background: #17184b;
  }
  .slick-arrow {
    box-shadow: 1px 1px 0 0 #17184b;
    width: 40px;
    height: 38px;
  }
  .slick-arrow.prev::before {
    top: calc(50% + 1px);
    left: calc(50% + 2px);
    width: 8px;
    height: 8px;
  }
  .slick-arrow.next::before {
    top: calc(50% + 1px);
    left: calc(50% - 2px);
    width: 8px;
    height: 8px;
  }
  #notFound .ttlArea h1 {
    font-size: 30px;
  }
  #notFound .ttlArea p {
    font-size: 16px;
  }
  #notFound .txt {
    margin-top: 30px;
  }
  #notFound .btnLine2 {
    margin-top: 30px;
  }
  #faq {
    padding: 35px 0 40px;
  }
  #faq h2 {
    font-size: 23px;
  }
  #faq h3.title {
    font-size: 18px;
    padding-left: 20px;
  }
  #faq .faqWrapper {
    margin-top: 25px;
    gap: 15px;
  }
  #faq .faqBox {
    width: 100%;
    padding: 15px;
  }
  #faq .faqList {
    margin-top: 10px;
  }
  #faq .faqList > li {
    padding-left: 18px;
  }
  #faq .faqList > li:not(:first-child) {
    margin-top: 3px;
  }
  #faq .faqList > li:before {
    top: 12px;
    width: 11px;
    height: 9px;
  }
  #faq .faqList > li > a {
    font-size: 14px;
    line-height: 2;
    text-underline-offset: 3px;
  }
  /* Slider */
  /* ココから下には記入禁止 */
  .forPC {
    display: none !important;
  }
}
@media print, screen and (min-width: 769px) {
  body {
    font-size: 16px;
  }
  a[href^="tel:"] {
    pointer-events: none;
  }
  .ib {
    display: inline-block !important;
  }
  .btmContents .btmContentsLead {
    font-size: 26px;
    padding: 35px 60px;
    line-height: 1.69;
  }
  .btmContents .btmContentsList {
    display: flex;
  }
  .btmContents .btmContentsList li {
    width: 33.3333333333%;
  }
  .btmContents .btmContentsList li:not(:last-child) {
    border-right: 1px solid #b8b8b8;
  }
  .btmContents .btmContentsList li.listItemDownload > a::after {
    background-image: url(../images/common/listitem_img01.jpg);
  }
  .btmContents .btmContentsList li.listItemDownload > a:hover .icon.iconDocument {
    background-image: url(../images/common/icon_document_white.svg);
  }
  .btmContents .btmContentsList li.listItemContact > a::after {
    background-image: url(../images/common/listitem_img02.jpg);
  }
  .btmContents .btmContentsList li.listItemContact > a:hover .icon.iconMail {
    background-image: url(../images/common/icon_mail_white.svg);
  }
  .btmContents .btmContentsList li.listItemContact.noLink {
    padding: 45px 3.1% 35px;
    display: flex;
    flex-direction: column;
  }
  .btmContents .btmContentsList li > a {
    display: flex;
    flex-direction: column;
    min-height: 305px;
    padding: 45px 9.3% 35px;
  }
  .btmContents .btmContentsList li > a:hover {
    color: #fff;
  }
  .btmContents .btmContentsList li > a:hover .title {
    color: #fff;
  }
  .btmContents .btmContentsList li > a:hover .commonBtn {
    background-color: #17184b;
    color: #fff;
  }
  .btmContents .btmContentsList li > a:hover .commonBtn::before {
    background-image: url(../images/common/icon_arrow_white.svg);
  }
  .btmContents .btmContentsList li > a:hover::after {
    opacity: 1;
  }
  .btmContents .btmContentsList li > a::after {
    opacity: 0;
  }
  .btmContents .btmContentsList li .title {
    font-size: 24px;
  }
  .btmContents .btmContentsList li .title .icon.iconDocument {
    padding-left: 35px;
  }
  .btmContents .btmContentsList li .title .icon.iconMail {
    padding-left: 40px;
  }
  .btmContents .btmContentsList li .title .icon.iconMailMagazine {
    padding-left: 40px;
  }
  .btmContents .btmContentsList li .title .icon.iconMailMagazine::before {
    width: 27px;
    height: 29px;
  }
  .btmContents .btmContentsList li .magaginePolicy {
    font-size: 12px;
  }
  .btmContents .btmContentsList li p {
    margin: 30px 0 25px;
    line-height: 1.75;
  }
  .btmContents .btmContentsList li .btnWrap {
    margin-top: auto;
  }
  .btmContents .btmContentsList li .btnWrap .commonBtn {
    max-width: 288px;
    width: inherit;
  }
  .btmContents .btmContentsList li .mailMagazine {
    margin-top: auto;
  }
  .btmContents .btmContentsList li .mailMagazine .magazineInput {
    width: calc(100% - 105px);
    padding: 5px;
  }
  .btmContents .btmContentsList li .mailMagazine .magazineSubmit {
    box-shadow: 2px 2px 0 0 #17184b;
    cursor: pointer;
    width: 94px;
    padding: 5px;
    margin-left: 7px;
    transition: 0.3s cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  .btmContents .btmContentsList li .mailMagazine .magazineSubmit:hover {
    background-color: #17184b;
    color: #fff;
  }
  .btmContentsTel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
  }
  .btmContentsTel .telText {
    font-size: 24px;
    margin-right: 70px;
  }
  .btmContentsTel .telNum {
    margin: 0;
  }
  .btmContentsTel .telNum a {
    font-size: 40px;
    padding-left: 45px;
  }
  #pageTop {
    top: -20px;
    right: 25px;
  }
  #pageTop:hover span {
    background-image: url(../images/common/pagetop_hov.svg);
  }
  #globalFooter {
    padding: 60px 0 0;
  }
  #globalFooter .footInfoBlock .inner {
    display: flex;
    justify-content: space-between;
  }
  #globalFooter .logoArea {
    align-self: center;
    margin-right: 10px;
  }
  #globalFooter .logoArea img {
    width: 122px;
  }
  #globalFooter .textArea {
    font-size: 13px;
    line-height: 2.23;
  }
  #globalFooter .textArea:not(:last-child) {
    margin-right: 10px;
  }
  #globalFooter .navList li a {
    font-size: 15px;
  }
  #globalFooter .footNavBlock {
    margin-top: 60px;
  }
  #globalFooter .footNavBlock .inner {
    display: flex;
    justify-content: space-between;
  }
  #globalFooter .footNavBlock .navArea {
    width: 75%;
  }
  #globalFooter .footNavBlock .navList > li {
    width: calc(25% - 10px);
  }
  #globalFooter .footNavBlock .navList > li:nth-child(n+5) {
    margin-top: 40px;
  }
  #globalFooter .footNavBlock .navList > li:not(:nth-child(4n)) {
    margin-left: 10px;
  }
  #globalFooter .footNavBlock .navList > li > a {
    font-size: 15px;
    padding-left: 30px;
  }
  #globalFooter .footNavBlock .navList > li > a:hover span::before {
    transform: scale(1, 1);
  }
  #globalFooter .footNavBlock .navList > li > a::before {
    content: "";
    background-color: #fff;
    display: block;
    position: absolute;
    width: 15px;
    height: 1px;
    top: 10px;
    left: 0;
  }
  #globalFooter .footNavBlock .navList > li > a span {
    position: relative;
  }
  #globalFooter .footNavBlock .navList > li > a span::before {
    content: "";
    background-color: #fff;
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -8px;
    transform-origin: left;
    transform: scale(0, 1);
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalFooter .footNavBlock .navList > li.snsItem ul li:not(:last-child) {
    margin-right: 20px;
  }
  #globalFooter .footNavBlock .navList > li.snsItem ul li a {
    transition: 0.3s opacity cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalFooter .footNavBlock .navList > li.snsItem ul li a:hover {
    opacity: 0.7;
  }
  #globalFooter .footNavBlock .bnrArea {
    width: 25%;
  }
  #globalFooter .footNavBlock .bnrList li + li {
    margin-top: 20px;
  }
  #globalFooter .footNavBlock .bnrList li a {
    transition: 0.3s opacity cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalFooter .footNavBlock .bnrList li a:hover {
    opacity: 0.7;
  }
  #globalFooter .footNavBlock .bnrList li img {
    width: 310px;
  }
  #globalFooter .footSubNavBlock {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    padding: 15px 30px;
  }
  #globalFooter .subNavList li:not(:last-child) {
    margin-right: 30px;
  }
  #globalFooter .subNavList li a {
    font-size: 13px;
    position: relative;
  }
  #globalFooter .subNavList li a:hover span::before {
    transform: scale(1, 1);
  }
  #globalFooter .subNavList li a span {
    position: relative;
  }
  #globalFooter .subNavList li a span::before {
    content: "";
    background-color: #fff;
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -8px;
    transform-origin: left;
    transform: scale(0, 1);
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalFooter .copyright {
    margin-top: 0;
  }
  .btnWrap {
    margin-top: 40px;
  }
  .commonBtn {
    box-shadow: 2px 2px 0 0 #17184b;
    width: 288px;
    padding: 14px 40px;
    line-height: 1.2;
    transition: all 0.3s;
  }
  .commonBtn:hover {
    background-color: #17184b;
    color: #fff;
  }
  .commonBtn:hover::before {
    background-image: url(../images/common/icon_arrow_white.svg);
  }
  .commonBtn.btnBack {
    font-size: 15px;
    padding: 14px 45px 14px 45px;
    width: 346px;
  }
  .commonBtn.btnBack:hover::before, .commonBtn.btnBack:hover::after {
    background-image: url(../images/common/icon_arrow_white.svg);
  }
  .commonBtn.btnOrange {
    box-shadow: 2px 2px 0 0 #e8651a;
  }
  .commonBtn.btnOrange:hover {
    background-color: #e8651a;
  }
  .btnS {
    width: 25%;
    min-width: 346px;
  }
  .btnM {
    width: 40%;
  }
  .btnL {
    width: 60%;
  }
  .btnLine2 {
    display: flex;
    overflow: hidden;
    margin-bottom: 50px;
  }
  .btnLine2 a {
    float: left;
    width: 49%;
    margin-right: 1%;
    margin-bottom: 10px;
  }
  .btnLine2 a:nth-child(2n) {
    width: 50%;
    margin-right: 0;
  }
  .btnLine3 {
    display: flex;
    overflow: hidden;
    margin-bottom: 50px;
  }
  .btnLine3 a {
    width: 32%;
    float: left;
    margin-right: 1%;
    margin-bottom: 10px;
  }
  .btnLine3 a.commonWhBtn {
    margin-bottom: 10px;
  }
  .btnLine3 a:nth-child(3n) {
    width: 34%;
    margin-right: 0;
  }
  .btnLine4 {
    overflow: hidden;
    margin-bottom: 50px;
  }
  .btnLine4 a {
    float: left;
    width: 24%;
    margin-right: 1%;
    margin-bottom: 10px;
  }
  .btnLine4 a.commonWhBtn {
    margin-bottom: 10px;
  }
  .btnLine4 a:nth-child(4n) {
    width: 25%;
    margin-right: 0;
  }
  .moreBtn > span, .moreBtn > a {
    font-size: 17px;
    padding-right: 40px;
  }
  #news h2 {
    margin-bottom: 40px;
  }
  #news .inner {
    width: 910px;
  }
  .inner.archive {
    width: 910px;
  }
  .newsList li {
    margin-bottom: 30px;
  }
  .newsList li:hover::after {
    transform: scale(1, 1);
  }
  .newsList li a {
    padding-bottom: 30px;
  }
  .newsList li a:hover::after {
    right: 15px;
  }
  .newsList li a::after {
    right: 20px;
    transition: 0.3s;
  }
  .newsList .date {
    width: 12.8%;
    padding: 0 1.7%;
    font-size: 14px;
  }
  .newsList .newsTitle {
    width: 87.2%;
    padding-right: 7.6%;
  }
  .slideList {
    display: flex;
    margin-top: 40px;
  }
  .slideList .slideListItem {
    width: 32.3%;
  }
  .slideList .slideListItem:not(:last-child) {
    margin-right: 1%;
  }
  .slideList .slideListItem > a:hover img {
    transform: scale(1.1);
  }
  .slideList .slideListItem > a:hover .moreBtn > span {
    background-image: url(../images/common/icon_arrow_more_hov.svg);
  }
  .slideList .slideListItem .slideListSub {
    font-size: 13px;
    margin-top: 15px;
  }
  .slideList.noSlideListPC {
    flex-flow: wrap;
  }
  .slideList.noSlideListPC .slideListItem {
    width: calc(33.3333333333% - 27px);
  }
  .slideList.noSlideListPC .slideListItem:not(:last-child) {
    margin-right: 0;
  }
  .slideList.noSlideListPC .slideListItem:not(:nth-of-type(3n + 1)) {
    margin-left: 40px;
  }
  .slideList.noSlideListPC .slideListItem:nth-child(n+4) {
    margin-top: 40px;
  }
  .slick-list {
    padding: 30px 0 !important;
  }
  .slick-list .slick-slide {
    transform: scale(0.95);
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
    transform-origin: bottom;
  }
  .slick-list .slick-slide:not(:last-child) {
    margin-right: 0;
  }
  .slick-list .slick-slide:not(.slick-center):hover img {
    transform: scale(1.1);
  }
  .slick-dots {
    padding-right: 120px;
    text-align: right;
  }
  .slick-dots li {
    height: 10px;
    width: 10px;
  }
  .slick-dots li button {
    height: 7px;
    width: 7px;
  }
  .slick-dots li button:hover, .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:before {
    width: 20px;
    height: 20px;
  }
  .slick-dots li.slick-active button {
    background: #17184b;
  }
  .slick-arrow {
    box-shadow: 2px 2px 0 0 #17184b;
    width: 40px;
    height: 30px;
  }
  .slick-arrow:hover {
    background-color: #17184b;
  }
  .slick-arrow:hover::before {
    border-color: #fff;
  }
  .slick-arrow.prev::before {
    top: calc(50% + 1px);
    left: calc(50% + 2px);
    width: 8px;
    height: 8px;
  }
  .slick-arrow.next::before {
    top: calc(50% + 1px);
    left: calc(50% - 2px);
    width: 8px;
    height: 8px;
  }
  #notFound .ttlArea h1 {
    font-size: 40px;
  }
  #notFound .ttlArea p {
    font-size: 18px;
  }
  #notFound .txt {
    margin-top: 40px;
  }
  #notFound .btnLine2 {
    margin-top: 40px;
  }
  #faq {
    padding: 70px 0 80px;
  }
  #faq h2 {
    font-size: 34px;
  }
  #faq h3.title {
    font-size: 20px;
    padding-left: 25px;
  }
  #faq .faqWrapper {
    margin-top: 40px;
    gap: 25px 1.65%;
  }
  #faq .faqBox {
    width: 32.2333333333%;
    padding: 30px 2.1%;
  }
  #faq .faqList {
    margin-top: 20px;
  }
  #faq .faqList > li {
    padding-left: 26px;
  }
  #faq .faqList > li:hover:before {
    left: 5px;
  }
  #faq .faqList > li:not(:first-child) {
    margin-top: 5px;
  }
  #faq .faqList > li:before {
    top: 10px;
    width: 15px;
    height: 13px;
    transition: 0.3s;
  }
  #faq .faqList > li > a {
    font-size: 16px;
    line-height: 2;
    text-underline-offset: 5px;
  }
  #faq .faqList > li > a:hover {
    text-decoration: underline;
    text-decoration-color: #17184b;
  }
  /* Slider */
  /* ココから下には記入禁止 */
  .forSP {
    display: none !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .ib {
    display: inline-block !important;
  }
  .btmContents .btmContentsList li .mailMagazine .magazineInput {
    width: 100%;
    max-width: 100%;
  }
  .btmContents .btmContentsList li .mailMagazine .magazineSubmit {
    margin-top: 20px;
  }
  #globalFooter .footNavBlock .navList > li > a::before {
    content: "";
    background-color: #fff;
    display: block;
    position: absolute;
    width: 15px;
    height: 1px;
    top: 10px;
    left: 0;
  }
  #globalFooter .footNavBlock .navList > li > a span {
    position: relative;
  }
  #globalFooter .footNavBlock .navList > li > a span::before {
    content: "";
    background-color: #fff;
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -8px;
    transform-origin: left;
    transform: scale(0, 1);
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalFooter .footNavBlock .navList > li.snsItem ul li:not(:last-child) {
    margin-right: 20px;
  }
  #globalFooter .subNavList li a span {
    position: relative;
  }
  #globalFooter .subNavList li a span::before {
    content: "";
    background-color: #fff;
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -8px;
    transform-origin: left;
    transform: scale(0, 1);
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  .btnLine3 a.commonWhBtn {
    margin-bottom: 10px;
  }
  .btnLine4 a.commonWhBtn {
    margin-bottom: 10px;
  }
  .slick-dots li button:hover, .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li.slick-active button {
    background: #17184b;
  }
  /* Slider */
  /* ココから下には記入禁止 */
}
@media screen and (max-width: 1200px) {
  main {
    margin-top: 60px;
  }
  #globalheader {
    background-color: #17184b;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: 0.3s cubic-bezier(0.25, 0.1, 0, 0.72) all;
  }
  #globalheader .headInner {
    width: 100%;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #globalheader .headInner .logo {
    margin-left: 20px;
    position: relative;
    z-index: 10;
  }
  #globalheader .headInner .logo a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  #globalheader .headInner .logo a img {
    width: 92px;
  }
  #globalheader .headInner #menubtn {
    width: 60px;
    height: 60px;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
  }
  #globalheader .headInner #menubtn span {
    width: 23px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 18px;
    transition: all 0.4s cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalheader .headInner #menubtn span:nth-of-type(1) {
    top: 19px;
  }
  #globalheader .headInner #menubtn span:nth-of-type(2) {
    top: 27px;
  }
  #globalheader .headInner #menubtn span:nth-of-type(3) {
    top: 35px;
  }
  #globalheader .headInner #menubtn.isOpen span:nth-of-type(1) {
    top: 24px;
    transform: rotate(-45deg);
  }
  #globalheader .headInner #menubtn.isOpen span:nth-of-type(2) {
    opacity: 0;
  }
  #globalheader .headInner #menubtn.isOpen span:nth-of-type(3) {
    top: 24px;
    transform: rotate(45deg);
  }
  #globalheader .headInner nav {
    position: fixed;
    width: 100%;
    margin: 60px auto 0;
    background-color: #17184b;
    top: 0;
    right: -100%;
    transform-origin: top;
    transform: scale(1, 1);
    z-index: 4;
    transition: all 0.3s;
    padding: 30px 0 60px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  #globalheader .headInner nav::-webkit-scrollbar {
    display: none;
  }
  #globalheader .headInner nav.isOpen {
    right: 0;
  }
  #globalheader .headInner nav .navInner {
    padding: 0 28px;
  }
  #globalheader .headInner nav .navInner .gNavList > li {
    position: relative;
  }
  #globalheader .headInner nav .navInner .gNavList > li a {
    color: #fff;
    font-size: 14px;
    font-size: clamp(13px, 2.2vw, 15px);
    font-weight: bold;
    display: block;
    padding: 20px 30px;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuShowy {
    display: inline-block;
    margin-top: 17px;
    width: 100%;
    max-width: 600px;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuShowy a {
    border-radius: 3px;
    display: block;
    font-weight: bold;
    padding: 15px 10px;
    text-align: center;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuShowy a span {
    position: relative;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuShowy a .icon {
    display: inline-block;
    margin-right: 20px;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuShowy a .icon::before {
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: 0.3s all;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuShowy a .icon.iconMaterial::before {
    background-image: url(../images/common/icon_document_white.svg);
    width: 14px;
    height: 18px;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuShowy a .icon.iconMail::before {
    background-image: url(../images/common/icon_mail_white.svg);
    width: 18px;
    height: 12px;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuMaterial a {
    background-color: #f07800;
    border: 1px solid #fff;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuBlank span {
    position: relative;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuBlank span::after {
    content: "";
    display: block;
    background: url(../images/common/icon_window_white.svg) no-repeat center/100%;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuContact {
    margin-right: 5%;
  }
  #globalheader .headInner nav .navInner .gNavList > li.menuContact a {
    background-color: #fff;
    color: #17184b;
  }
  #globalheader .headInner nav .navInner .gNavList > li.telBtn {
    margin-top: 20px;
    max-width: 600px;
    background-color: #fb8d20;
    border: 1px solid #fff;
    border-radius: 3px;
  }
  #globalheader .headInner nav .navInner .gNavList > li.telBtn a {
    text-align: center;
    padding: 14px 10px;
  }
  #globalheader .headInner nav .navInner .gNavList > li.telBtn a span {
    position: relative;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.03em;
    padding-left: 20px;
  }
  #globalheader .headInner nav .navInner .gNavList > li.telBtn a span::before {
    content: "";
    display: block;
    background: url(../images/common/icon_smartphone_wht.svg) no-repeat center/100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 15px;
    height: 23px;
  }
  .spHeadOverlay.show {
    display: block;
  }
  .forPC1200 {
    display: none !important;
  }
}
@media print, screen and (min-width: 1201px) {
  main {
    margin-top: 75px;
  }
  #globalheader {
    background-color: #17184b;
    width: 100%;
    height: 75px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: 0.3s all cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalheader.isScroll {
    background-color: #fff;
    box-shadow: 2px 0px 4px 2px rgba(0, 0, 0, 0.05);
  }
  #globalheader .headInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #globalheader .headInner .logo {
    margin-left: 25px;
    transition: 0.3s all cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalheader .headInner .logo a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  #globalheader .headInner .logo a img {
    width: 92px;
  }
  #globalheader .headInner #menubtn {
    display: none;
  }
  #globalheader .headInner nav {
    height: 75px;
  }
  #globalheader .headInner nav .navInner .gNavList {
    display: flex;
    align-items: center;
    height: 75px;
    padding-left: 10px;
  }
  #globalheader .headInner nav .navInner .gNavList li {
    overflow: hidden;
  }
  #globalheader .headInner nav .navInner .gNavList li a {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-size: clamp(12px, 1vw, 16px);
    padding: 32px 14px;
    color: #fff;
    position: relative;
    transition: 0.3s all cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalheader .headInner nav .navInner .gNavList li a:hover {
    font-weight: 500;
  }
  #globalheader .headInner nav .navInner .gNavList li a:hover span::before {
    transform: scale(1, 1);
  }
  #globalheader .headInner nav .navInner .gNavList li span {
    position: relative;
    letter-spacing: 0.05rem;
  }
  #globalheader .headInner nav .navInner .gNavList li span::before {
    content: "";
    background-color: #fff;
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -8px;
    transform-origin: left;
    transform: scale(0, 1);
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  #globalheader .headInner nav .navInner .gNavList li:nth-last-child(2) a {
    padding: 32px 32px 32px 16px;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuShowy a {
    height: 75px;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuShowy a .icon {
    display: inline-block;
    margin-right: 20px;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuShowy a .icon::before {
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-38%);
  }
  #globalheader .headInner nav .navInner .gNavList li.menuShowy a .icon.iconMaterial::before {
    background-image: url(../images/common/icon_document_white.svg);
    width: 14px;
    height: 18px;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuShowy a .icon.iconMail {
    margin-right: 25px;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuShowy a .icon.iconMail::before {
    background-image: url(../images/common/icon_mail_white.svg);
    width: 19px;
    height: 15px;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuMaterial a {
    background-color: #f07800;
    font-weight: bold;
    padding: 32px 24px;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuMaterial a span::before {
    content: none;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuMaterial a:hover {
    background-color: #a25507;
    color: #fff;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuMaterial a:hover .icon::before {
    background-image: url(../images/common/icon_mail_white.svg);
  }
  #globalheader .headInner nav .navInner .gNavList li.menuBlank a {
    padding: 32px 42px 32px 16px;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuBlank span {
    position: relative;
  }
  #globalheader .headInner nav .navInner .gNavList li.menuBlank span::after {
    content: "";
    display: block;
    background: url(../images/common/icon_window_white.svg) no-repeat center/100%;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -18px;
  }
  #globalheader .headInner nav .navInner .gNavList li.telBtn {
    height: 75px;
    background-color: #fb8d20;
    border-right: solid 1px #17184b;
  }
  #globalheader .headInner nav .navInner .gNavList li.telBtn a {
    pointer-events: none;
    padding: 23px 15px;
  }
  #globalheader .headInner nav .navInner .gNavList li.telBtn a span {
    position: relative;
    font-family: "Jost", sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.06em;
    padding-left: 25px;
  }
  #globalheader .headInner nav .navInner .gNavList li.telBtn a span::before {
    content: "";
    display: block;
    background: url(../images/common/icon_smartphone_wht.svg) no-repeat center/100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 19px;
    height: 30px;
  }
  .spHeadOverlay.show {
    display: block;
  }
  .forSP1200 {
    display: none !important;
  }
}